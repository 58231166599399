import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box, Flex, Button, Link } from "rebass";
import { Avatar, Banner, NavGrid, GumroadButton, Container, Grid, Title, Typeform, Video } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Title h1 mdxType="Title">
    Thank You For Purchasing ServerlessReact.Dev ❤️
    </Title>
    <Container width={[1, 3 / 4, 1 / 2]} mt={[2, 2, 4]} mdxType="Container">
      <Typeform url="https://swizecteller.typeform.com/to/faMda1" mdxType="Typeform" />
      <p>{`Thank you for purchasing ServerlessReact.Dev, I'm excited to see what you build :)`}</p>
      <p>{`Look for an email with your login details. You'll have to reset your password first.`}</p>
      <p>{`If you need any further help on your journey to becoming an Indie Hacker and making your first $1,000/month online, check out the offers below.`}</p>
      <p>{`Got questions? Hit me up on `}<a parentName="p" {...{
          "href": "mailto:hi@swizec.com"
        }}>{`hi@swizec.com`}</a>{` or on twitter as @swizec`}</p>
    </Container>
    <Title h1 mdxType="Title">
   Improve your journey with these offers
    </Title>
    <Container width={[1, 3 / 4, 1 / 2]} mt={[2, 2, 4]} mdxType="Container">
      <Video id="0iZpIEu11T8" mdxType="Video" />
      <h2 {...{
        "id": "find-your-idea"
      }}>{`Find your idea`}</h2>
      <p>{`You know how to build whatever your heart desires.`}</p>
      <p>{`But how do you come up with a good idea? How do you choose which product is worth building? How do you find and validate a market? Ultimately, how do you make sure you're not wasting your time?`}</p>
      <p>{`Join this mini course and learn the process I use to create and validate ideas, discover customer pains, and learn what's worth building.`}</p>
      <p>{`Delivered as a 1 hour webinar in February, then turned into a course. Exact date determined by popular vote.`}</p>
      <Box textAlign="center" mt={2} mb={5} mdxType="Box">
    <Button as="a" href="https://gumroad.com/l/fKfYf?wanted=true" data-gumroad-product-id="fKfYf" mdxType="Button">
    Find Your Idea Mini Course for $49
    </Button>
      </Box>
      <Video id="1v_rduzSkdE" mdxType="Video" />
      <h2 {...{
        "id": "turn-your-idea-into-a-business"
      }}>{`Turn your idea into a business`}</h2>
      <p>{`You know what to build and you know how to build it. That means you're way ahead of most people. Congratz!`}</p>
      <p>{`But how do you turn a launched product into a business you can rely on? How do you build a marketing engine that turns visitors into customers?`}</p>
      <p>{`Join this course and learn everything I've learned making my first `}<strong parentName="p">{`$100,000 in product sales`}</strong>{`. And how I turned that success into a predictable monthly income I can rely on.`}</p>
      <p>{`Learn how to think about marketing like an engineer, build a funnel, and create a stable marketing engine that runs itself.`}</p>
      <p>{`Delivered as a 2 hour webinar in February, then turned into a course. Exact date determined by popular vote.`}</p>
      <Box textAlign="center" mt={2} mb={5} mdxType="Box">
    <Button as="a" href="https://gumroad.com/l/vhTOH?wanted=true" data-gumroad-product-id="vhTOH" mdxType="Button">
    Turn Your Idea Into A Business Course for $99
    </Button>
      </Box>
      <Video id="ylMDVIIfVjY" mdxType="Video" />
      <h2 {...{
        "id": "1on1-coaching-on-your-journey"
      }}>{`1on1 Coaching On Your Journey`}</h2>
      <p>{`If you're serious about making 2020 your year and finally launching that idea, you've always been dreaming of, this is the most advanced program I offer.`}</p>
      <p>{`Get `}<strong parentName="p">{`10 hours of 1-on-1 coaching`}</strong>{` with video calls, recordings of every session, and in-depth recaps with action steps. `}</p>
      <p>{`Here's how it works:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`we `}<strong parentName="p">{`start with a roadmapping session`}</strong>{`, define where you are in your journey, and come up with action steps to take you to a launched product`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`then we meet every 2 weeks and `}<strong parentName="p">{`help you stay on track`}</strong></p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`I help you resolve technical issues, discuss marketing approaches, how to get feedback from users, and `}<strong parentName="p">{`optimize your MVP for learning fast`}</strong></p>
        </li>
      </ul>
      <p>{`After the program you are `}<strong parentName="p">{`set up for indie hacker success`}</strong>{`. `}</p>
      <p>{`You'll have a working product and a pathway to learning exactly what your customers want so you can iterate fast.`}</p>
      <Box textAlign="center" mt={2} mb={5} mdxType="Box">
    <Button as="a" href="https://gumroad.com/l/GUfrw?wanted=true" data-gumroad-product-id="GUfrw" mdxType="Button">
   Get 1on1 Coaching On Your Journey for $3,000
    </Button>
      </Box>
      <Box mb={5} mdxType="Box">
        <Link to="/module-0/" mb={5} mdxType="Link">Go to course 👉</Link>
      </Box>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      